<template>
  <svg
    width="50"
    height="50"
    viewBox="0 0 50 50"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M31.25 30.5L38.5 34.75L36.5 38L27.5 32.5V20H31.25V30.5ZM50 30C50 41.025 41.025 50 30 50C24.95 50 20.35 48.1 16.825 45H5C2.125 45 0 42.875 0 40V17.5C0 14.7 2.225 12.6 5 12.5V11.25C5 5.025 10.025 0 16.25 0C22.1 0 26.85 4.475 27.4 10.2C28.25 10.075 29.125 10 30 10C41.025 10 50 18.975 50 30ZM10 12.5H22.5V10.65C22.2 7.475 19.5 5 16.25 5C12.8 5 10 7.8 10 11.25V12.5ZM45 30C45 21.725 38.275 15 30 15C21.725 15 15 21.725 15 30C15 38.275 21.725 45 30 45C38.275 45 45 38.275 45 30Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {
  name: "IconWallet",
};
</script>
