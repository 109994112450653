<template>
  <div>
    <a
      class="btn btn-primary text-lg"
      href="#"
      id="show-modal"
      @click="showModal = true"
      role="button"
      ><svg
        class="mr-3"
        width="19"
        height="18"
        viewBox="0 0 19 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M14.2804 9C14.2804 9.8284 13.6356 10.5 12.8404 10.5C12.0451 10.5 11.4004 9.8284 11.4004 9C11.4004 8.1716 12.0451 7.5 12.8404 7.5C13.6356 7.5 14.2804 8.1716 14.2804 9Z"
          fill="#E6CFFC"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M17.6404 2V4.28C18.2068 4.63 18.6004 5.26 18.6004 6V12C18.6004 12.74 18.2068 13.37 17.6404 13.72V16C17.6404 17.1 16.7764 18 15.7204 18H2.28035C1.21475 18 0.360352 17.1 0.360352 16V2C0.360352 0.9 1.21475 0 2.28035 0H15.7204C16.7764 0 17.6404 0.9 17.6404 2ZM9.96035 12H16.6804V6H9.96035V12ZM2.28035 2V16H15.7204V14H9.96035C8.90435 14 8.04035 13.1 8.04035 12V6C8.04035 4.9 8.90435 4 9.96035 4H15.7204V2H2.28035Z"
          fill="#E6CFFC"
        />
      </svg>
      Login with Wallet</a
    >
    <modal :show="showModal" @close="showModal = false">
      <template #header>
        <div class="text-center pt-3">
          <svg
            class="mx-auto"
            width="46"
            height="46"
            viewBox="0 0 46 46"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M35.1999 23C35.1999 25.071 33.5881 26.75 31.5999 26.75C29.6117 26.75 27.9999 25.071 27.9999 23C27.9999 20.929 29.6117 19.25 31.5999 19.25C33.5881 19.25 35.1999 20.929 35.1999 23Z"
              fill="white"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M43.5999 5.5V11.2C45.0159 12.075 45.9999 13.65 45.9999 15.5V30.5C45.9999 32.35 45.0159 33.925 43.5999 34.8V40.5C43.5999 43.25 41.4399 45.5 38.7999 45.5H5.1999C2.5359 45.5 0.399902 43.25 0.399902 40.5V5.5C0.399902 2.75 2.5359 0.5 5.1999 0.5H38.7999C41.4399 0.5 43.5999 2.75 43.5999 5.5ZM24.3999 30.5H41.1999V15.5H24.3999V30.5ZM5.1999 5.5V40.5H38.7999V35.5H24.3999C21.7599 35.5 19.5999 33.25 19.5999 30.5V15.5C19.5999 12.75 21.7599 10.5 24.3999 10.5H38.7999V5.5H5.1999Z"
              fill="white"
            />
          </svg>
        </div>
        <h1
          class="modal-title px-6 pt-6 pb-1 text-white text-center text-3xl font-bold uppercase"
        >
          Login with wallet
        </h1>
        <p class="px-6 pb-5 pt-3 text-white text-center text-md font-bold">
          To lock your NXTT please log in with your wallet
        </p>
      </template>
      <template #body>
        <button
          v-if="hasEthProvider"
          type="button"
          class="btn btn-primary text-uppercase w-full mt-4"
          @click="startMetaMaskConnect"
        >
          <svg
            class="mr-3"
            width="19"
            height="18"
            viewBox="0 0 19 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M14.2799 9C14.2799 9.8284 13.6351 10.5 12.8399 10.5C12.0446 10.5 11.3999 9.8284 11.3999 9C11.3999 8.1716 12.0446 7.5 12.8399 7.5C13.6351 7.5 14.2799 8.1716 14.2799 9Z"
              fill="#E6CFFC"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M17.6399 2V4.28C18.2063 4.63 18.5999 5.26 18.5999 6V12C18.5999 12.74 18.2063 13.37 17.6399 13.72V16C17.6399 17.1 16.7759 18 15.7199 18H2.27986C1.21426 18 0.359863 17.1 0.359863 16V2C0.359863 0.9 1.21426 0 2.27986 0H15.7199C16.7759 0 17.6399 0.9 17.6399 2ZM9.95986 12H16.6799V6H9.95986V12ZM2.27986 2V16H15.7199V14H9.95986C8.90386 14 8.03986 13.1 8.03986 12V6C8.03986 4.9 8.90386 4 9.95986 4H15.7199V2H2.27986Z"
              fill="#E6CFFC"
            />
          </svg>

          Login with wallet
        </button>
        <button
          v-if="!hasEthProvider"
          type="button"
          class="btn btn-primary text-uppercase w-full mt-4"
          @click="startWalletConnect"
        >
          <svg
            class="mr-3"
            width="19"
            height="18"
            viewBox="0 0 19 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M14.2799 9C14.2799 9.8284 13.6351 10.5 12.8399 10.5C12.0446 10.5 11.3999 9.8284 11.3999 9C11.3999 8.1716 12.0446 7.5 12.8399 7.5C13.6351 7.5 14.2799 8.1716 14.2799 9Z"
              fill="#E6CFFC"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M17.6399 2V4.28C18.2063 4.63 18.5999 5.26 18.5999 6V12C18.5999 12.74 18.2063 13.37 17.6399 13.72V16C17.6399 17.1 16.7759 18 15.7199 18H2.27986C1.21426 18 0.359863 17.1 0.359863 16V2C0.359863 0.9 1.21426 0 2.27986 0H15.7199C16.7759 0 17.6399 0.9 17.6399 2ZM9.95986 12H16.6799V6H9.95986V12ZM2.27986 2V16H15.7199V14H9.95986C8.90386 14 8.03986 13.1 8.03986 12V6C8.03986 4.9 8.90386 4 9.95986 4H15.7199V2H2.27986Z"
              fill="#E6CFFC"
            />
          </svg>

          Login with wallet
        </button>
      </template>
      <template #footer></template>
    </modal>
  </div>
</template>

<script>
import connect from "@/helpers/index";
import Modal from "@/modals/BasicModal.vue";
export default {
  name: "ConnectValletBtn",
  components: {
    Modal,
  },
  data() {
    return {
      showModal: false,
      hasEthProvider: false,
    };
  },
  props: {
    msg: String,
  },
  created() {
    this.emitter.on("show-login", (evt) => {
      this.showModal = true;
    });
  },
  methods: {
    startWalletConnect: function () {
      this.connectUserWallet();
      this.showModal = false;
    },
    startMetaMaskConnect: async function () {
      let ethereum = window.ethereum;
      if (ethereum) {
        const accounts = await ethereum.request({
          method: "eth_requestAccounts",
        });

        ethereum.on("accountsChanged", (accounts) => {
          this.disconnectUser();
        });

        ethereum.on("chainChanged", (chainId) => {
          this.disconnectUser();
        });

        this.state.walletType = "MetaMask";
        this.state.status = true;
        this.state.address = accounts[0];
        this.state.chainId = await ethereum.request({ method: "eth_chainId" });
      }
      this.showModal = false;
    },
  },
  setup: () => {
    const { connectWalletConnect, disconnectWallet, state } = connect();
    const connectUserWallet = async () => {
      await connectWalletConnect();
    };

    const disconnectUser = async () => {
      await disconnectWallet();
    };

    return {
      connectUserWallet,
      disconnectUser,
      state,
    };
  },
  mounted() {
    let ethereum = window.ethereum;
    if (typeof ethereum !== "undefined") {
      this.hasEthProvider = true;

      ethereum.on("accountsChanged", (accounts) => {
        this.disconnectUser();
      });

      ethereum.on("chainChanged", (chainId) => {
        this.disconnectUser();
      });
    }
  },
};
</script>
