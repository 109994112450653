<template>
  <div id="app-content">
    <router-view></router-view>
  </div>
</template>

<script>
import connect from "@/helpers/index";
export default {
  name: "App",
  components: {},
  setup: () => {
    const { disconnectWallet, state } = connect();

    const disconnectUser = async () => {
      await disconnectWallet();
    };

    return {
      disconnectUser,
      state,
    };
  },
  watch: {
    // "state.address": function (val, new_val) {
    //   if (val != null) {
    //     this.$router.push("/dashboard");
    //   }
    // },
  },
};
</script>
