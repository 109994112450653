<template>
  <div
    id="metaverse-wrapper"
    class="absolute lg:left-1/2 transform lg:-translate-x-1/2 mx-auto"
  >
    <div id="metaverse">
      <div class="metaverse-bg text-center mx-auto">
        <div class="glow"></div>
        <div class="planets">
          <div class="elipses"></div>

          <div class="planet-1-path rotate-planet-1">
            <div class="planet-1"></div>
          </div>
          <div class="planet-2-path rotate-planet-2">
            <div class="planet-2"></div>
          </div>
          <div class="planet-3-path rotate-planet-3">
            <div class="planet-3"></div>
          </div>
          <div class="planet-4-path rotate-planet-4">
            <div class="planet-4"></div>
          </div>
        </div>
      </div>
      <img
        src="@/assets/images/metaverse-ratio.png"
        width="100%"
        height="auto"
        alt=""
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.limited-otc-offer {
  position: relative;
}
#metaverse-wrapper {
  max-width: 800px;
  width: 100%;
  top: -250px;
  @media (max-width: 992px) {
    top: -160px !important;
    max-width: 100%;
  }
  z-index: -1;
  padding-top: 50px;
  #metaverse {
    width: 100%;
    position: relative;
    z-index: -2;
    .metaverse-bg {
      overflow: visible;
      position: absolute;
      height: 100%;
      width: 100%;
      padding: 0px;
      left: 0;
      top: 0;
      z-index: -1;

      .glow {
        position: absolute;
        z-index: 0;
        top: 0px;
        left: 0px;
        right: 0px;
        bottom: 0px;
        background-size: cover;
        background-image: url("@/assets/images/metaverse/glow.svg");
        background-position: center center;
        background-repeat: no-repeat;
      }

      .planets {
        position: relative;
        top: 0px;
        left: 0px;
        right: 0px;
        bottom: 0px;
        height: 100%;
        width: 100%;

        .elipses {
          position: absolute;
          z-index: -3;
          top: 0px;
          left: 0px;
          right: 0px;
          bottom: 0px;
          background-size: cover;
          background-image: url("@/assets/images/metaverse/elipses.svg");
          background-position: center center;
          background-repeat: no-repeat;
        }
        .planet-center {
          position: absolute;
          z-index: 1;
          top: 0px;
          left: 0px;
          right: 0px;
          bottom: 0px;
          background-size: cover;
          background-image: url("@/assets/images/metaverse/planet.png");
          background-position: center center;
          background-repeat: no-repeat;
        }
        .planet-1-path {
          position: absolute;
          z-index: -1;
          top: 0px;
          left: 0px;
          right: 0px;
          bottom: 0px;
          border-radius: 10%;

          .planet-1 {
            position: absolute;
            z-index: -2 !important;
            top: -4.3%;
            left: 46%;
            width: 8%;
            height: 8%;
            background-size: cover;
            background-image: url("@/assets/images/metaverse/planet-1.svg");
            background-position: center center;
            background-repeat: no-repeat;
          }
        }
        .planet-2-path {
          position: absolute;
          z-index: 1;
          top: 5%;
          left: 5%;
          right: 5%;
          bottom: 5%;
          border-radius: 50%;
          .planet-2 {
            position: absolute;
            z-index: 6;
            top: -3.5%;
            left: 27.5%;
            width: 40%;
            height: 40%;
            border-radius: 50%;
            overflow: visible;
            background-size: cover;
            background-image: url("@/assets/images/metaverse/planet-2.svg");
            background-position: center center;
            background-repeat: no-repeat;
          }
        }
        .planet-3-path {
          position: absolute;
          z-index: 1;
          top: 0px;
          left: 0px;
          right: 0px;
          bottom: 0px;
          border-radius: 50%;

          .planet-3 {
            position: absolute;
            z-index: 6;
            top: -8%;
            left: 23%;
            width: 23%;
            height: 23%;
            border-radius: 50%;
            overflow: hidden;
            background-size: cover;
            background-image: url("@/assets/images/metaverse/planet-3.svg");
            background-position: center center;
            background-repeat: no-repeat;
          }
        }

        .planet-4-path {
          position: absolute;
          z-index: 1;
          top: 10%;
          left: 10%;
          right: 10%;
          bottom: 10%;
          border-radius: 50%;

          .planet-4 {
            position: absolute;
            z-index: 6;
            top: -45%;
            left: 20%;
            width: 100%;
            height: 100%;
            border-radius: 50%;
            opacity: 0.85;
            overflow: hidden;
            background-size: cover;
            background-image: url("@/assets/images/metaverse/planet-4.png");
            background-position: center center;
            background-repeat: no-repeat;
            animation: rotation4-planet-reverse 155s infinite linear;
          }
        }

        .ne-logo {
          position: absolute;
          z-index: 7;
          top: calc(50% - 45px);
          left: calc(50% - 122px);
          width: 242px;
          height: 90px;

          @media (min-width: 768px) {
            top: calc(50% - 36px);
            left: calc(50% - 100px);
            width: 200px;
            height: 72px;
          }
          @media (min-width: 992px) {
            top: calc(50% - 45px);
            left: calc(50% - 122px);
            width: 242px;
            height: 90px;
          }
          @media (min-width: 1200px) {
            top: calc(50% - 45px);
            left: calc(50% - 122px);
            width: 242px;
            height: 90px;
          }
        }
      }
    }
  }
}
.rotate-planet-1 {
  animation: rotation1 100s infinite linear;
}
.rotate-planet-2 {
  animation: rotation2 110s infinite linear;
}
.rotate-planet-3 {
  animation: rotation3 200s infinite linear;
}
.rotate-planet-4 {
  animation: rotation4 155s infinite linear;
}

@keyframes rotation1 {
  from {
    transform: rotate(270deg);
  }
  to {
    transform: rotate(630deg);
  }
}
@keyframes rotation2 {
  from {
    transform: rotate(40deg);
  }
  to {
    transform: rotate(400deg);
  }
}
@keyframes rotation3 {
  from {
    transform: rotate(60deg);
  }
  to {
    transform: rotate(420deg);
  }
}
@keyframes rotation4 {
  from {
    transform: rotate(300deg);
  }
  to {
    transform: rotate(660deg);
  }
}

@keyframes rotation4-planet-reverse {
  from {
    transform: rotate(400deg);
  }
  to {
    transform: rotate(40deg);
  }
}
</style>
