<template>
  <basic-layout>
    <MetaverseWrapper />
    <Header />
    <div>
      <InfoPanel :title="'Ongoing whitelists'" :items="items.ongoing" />
    </div>

    <div>
      <InfoPanel
        class="mt-5"
        :title="'Upcoming whitelists'"
        :items="items.upcoming"
      />
      <div class="mb-5">
        <h2 class="text-2xl text-center font-bold pb-5 mb-10">COMING SOON!</h2>
      </div>
    </div>
  </basic-layout>
</template>

<script>
import MetaverseWrapper from "@/components/base/MetaverseWrapper.vue";
import Header from "@/components/Header.vue";
import Disclaimer from "../components/Disclaimer.vue";
import BasicLayout from "./../layouts/BasicLayout.vue";
import InfoPanel from "../components/InfoPanel.vue";
import BuyNxtt from "../components/BuyNxtt.vue";
import connect from "../helpers/index";
import { tokens } from "../data/data.json";
export default {
  name: "HomePage",
  components: {
    MetaverseWrapper,
    Header,
    BasicLayout,
    InfoPanel,
    BuyNxtt,
    Disclaimer,
  },
  setup: () => {
    const { state } = connect();
    return {
      state,
    };
  },
  data() {
    return {
      items: {
        ongoing: tokens.filter(function (item) {
          return item.type == "ongoing";
        }),
        upcoming: tokens.filter(function (item) {
          return item.type == "upcoming";
        }),
      },
    };
  },
  mounted() {},
  methods: {},
};
</script>
<style lang=""></style>
