<template>
  <div class="relative flex-grow max-w-full flex-1 px-3 mt-6 lg:mt-0">
    <a href="#" disabled class="block cursor-pointer">
      <GradientBox class="p-8 cursor-pointer">
        <div class="w-full flex justify-end">
          <component
            v-if="icon"
            :is="iconComponent"
            class="h-[2.875rem] w-[2.875rem] text-white/40"
          />
        </div>
        <h3 v-if="title" class="text-3xl text-white font-bold uppercase mb-6">
          {{ title }}
        </h3>
        <div v-if="content" v-html="content"></div>
        <div class="mt-5">
          <div class="btn btn-outline text-white cursor-pointer">CLOSED</div>
        </div>
      </GradientBox>
    </a>
  </div>
</template>

<script>
import connect from "@/helpers/index";
import { defineAsyncComponent } from "vue";
import GradientBox from "@/components/base/GradientBox.vue";
export default {
  components: {
    GradientBox,
  },
  props: {
    id: {
      required: true,
      type: Number,
      default: null,
    },
    icon: {
      required: false,
      type: String,
      default: null,
    },
    title: {
      required: false,
      type: String,
      default: null,
    },
    content: {
      required: false,
      type: String,
      default: null,
    },
  },
  setup: () => {
    const { state } = connect();
    return {
      state,
    };
  },
  computed: {
    link() {
      return "/token/" + this.id;
    },
    iconComponent() {
      return defineAsyncComponent(() =>
        import(`@/components/icons/${this.icon}.vue`)
      );
    },
  },
  methods: {
    clickCard: function () {
      if (this.state.address == "") {
        this.emitter.emit("show-login", {});
      } else {
        this.$router.push({
          path: this.link,
        });
      }
    },
  },
};
</script>
<style >
a.cursor-pointer{
  opacity: 0.6;
}</style>