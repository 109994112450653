<template>
  <svg
    class="mx-auto"
    width="46"
    height="46"
    viewBox="0 0 46 46"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M35.1999 23C35.1999 25.071 33.5881 26.75 31.5999 26.75C29.6117 26.75 27.9999 25.071 27.9999 23C27.9999 20.929 29.6117 19.25 31.5999 19.25C33.5881 19.25 35.1999 20.929 35.1999 23Z"
      fill="white"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M43.5999 5.5V11.2C45.0159 12.075 45.9999 13.65 45.9999 15.5V30.5C45.9999 32.35 45.0159 33.925 43.5999 34.8V40.5C43.5999 43.25 41.4399 45.5 38.7999 45.5H5.1999C2.5359 45.5 0.399902 43.25 0.399902 40.5V5.5C0.399902 2.75 2.5359 0.5 5.1999 0.5H38.7999C41.4399 0.5 43.5999 2.75 43.5999 5.5ZM24.3999 30.5H41.1999V15.5H24.3999V30.5ZM5.1999 5.5V40.5H38.7999V35.5H24.3999C21.7599 35.5 19.5999 33.25 19.5999 30.5V15.5C19.5999 12.75 21.7599 10.5 24.3999 10.5H38.7999V5.5H5.1999Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {
  name: "IconWallet",
};
</script>
