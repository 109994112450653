<template>
  <basic-layout>
    <MetaverseWrapper />
    <TokenDetails></TokenDetails>
  </basic-layout>
</template>

<script>
import MetaverseWrapper from "@/components/base/MetaverseWrapper.vue";
import Header from "@/components/Header.vue";
import BasicLayout from "./../layouts/BasicLayout.vue";
import connect from "../helpers/index";
import TokenDetails from "@/components/TokenDetails.vue";
import { tokens } from "../data/data.json";
export default {
  name: "HomePage",
  components: {
    MetaverseWrapper,
    Header,
    BasicLayout,
    TokenDetails,
  },
  setup: () => {
    const { state } = connect();
    return {
      state,
    };
  },
  data() {
    return {
      items: {
        ongoing: tokens.filter(function (item) {
          return item.type == "ongoing";
        }),
        upcoming: tokens.filter(function (item) {
          return item.type == "upcoming";
        }),
      },
    };
  },
  mounted() {
    if (this.state.address == "" || true) {
      this.$router.replace({
        path: "/",
      });
    }
  },
  methods: {},
};
</script>
<style lang=""></style>
