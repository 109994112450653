<template lang="">
  <div class="relative container mx-auto py-5 z-30">
    <GradientBox class="p-8 glass">
      <div class="w-full flex justify-end">
        <component
          v-if="item.icon"
          :is="iconComponent"
          class="h-[2.875rem] w-[2.875rem] text-white/40"
        />
      </div>
      <h3
        v-if="item.title"
        class="text-3xl text-white font-bold uppercase mb-6"
      >
        {{ item.title }}
      </h3>
      <div v-if="item.content" v-html="item.content"></div>
      <div>
        <h3
          class="text-2xl text-white font-bold uppercase text-center mb-4 pt-5"
        >
          Select your desired staking period
        </h3>
      </div>
      <div class="flex flex-col lg:flex-row pt-0 gap-10 lg:pt-6 pb-5">
        <GradientBox
          v-for="(item, i) in periods"
          :key="item"
          class="p-8 w-full cursor-pointer"
          @click="selectedPeriod(item)"
        >
          <div>
            <svg
              class="mx-auto"
              width="50"
              height="51"
              viewBox="0 0 50 51"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M31.25 30.5156L38.5 34.7656L36.5 38.0156L27.5 32.5156V20.0156H31.25V30.5156ZM50 30.0156C50 41.0406 41.025 50.0156 30 50.0156C24.95 50.0156 20.35 48.1156 16.825 45.0156H5C2.125 45.0156 0 42.8906 0 40.0156V17.5156C0 14.7156 2.225 12.6156 5 12.5156V11.2656C5 5.04063 10.025 0.015625 16.25 0.015625C22.1 0.015625 26.85 4.49062 27.4 10.2156C28.25 10.0906 29.125 10.0156 30 10.0156C41.025 10.0156 50 18.9906 50 30.0156ZM10 12.5156H22.5V10.6656C22.2 7.49063 19.5 5.01562 16.25 5.01562C12.8 5.01562 10 7.81563 10 11.2656V12.5156ZM45 30.0156C45 21.7406 38.275 15.0156 30 15.0156C21.725 15.0156 15 21.7406 15 30.0156C15 38.2906 21.725 45.0156 30 45.0156C38.275 45.0156 45 38.2906 45 30.0156Z"
                fill="white"
              />
            </svg>
          </div>
          <h4
            class="text-1xl text-white font-bold uppercase text-center mb-4 pt-3"
          >
            {{ item.title }}
          </h4>
          <p class="font-bold uppercase text-center">{{ item.subtitle }}</p>
          <p class="text-center mt-2">{{ item.desc }}</p>
          <div class="mt-5">
          <div class="btn btn-primary cursor-pointer">SELECT</div>
        </div>
        </GradientBox>
      </div>
      <div>
        <p class="text-center mt-2">
          Selecting one of the options above only indicates intent, not the
          actual length of the whitelist locking period. To start staking,
          you'll have to lock NXTT again on November 7.
        </p>
      </div>
    </GradientBox>
    <LockingModal
      class="fixed z-[10]"
      :item="item"
      :dayOfPeriod="selectedLockPeriod"
      v-model="showLockinkModal"
    />
  </div>
</template>
<script>
import { defineAsyncComponent } from "vue";
import GradientBox from "./base/GradientBox.vue";
import { tokens } from "../data/data.json";
import LockingModal from "./modals/LockingModal.vue";
export default {
  components: {
    GradientBox,
    LockingModal,
  },
  computed: {
    item: function () {
      const id = this.$route.params.id;
      return tokens.find((x) => x.id == id);
    },
    iconComponent() {
      return defineAsyncComponent(() =>
        import(`@/components/icons/${this.item.icon}.vue`)
      );
    },
  },
  data() {
    return {
      showLockinkModal: false,
      selectedLockPeriod: null,
      periods: [
        {
          day: 30,
          title: "30 days lock",
          subtitle:
            "30 days locking period gives you the following benefits for the staking.",
          desc: "Selecting the 30 days locking period you may use 100% of your APY boosts, and your APY could be between 10% and 20%",
        },
        {
          day: 90,
          title: "90 days lock",
          subtitle:
            "90 days locking period gives you the following benefits for the staking.",
          desc: "Selecting the 90 days locking period you may use 100% of your APY boosts, and your APY could be between 20% and 30%",
        },
        {
          day: 180,
          title: "180 days lock",
          subtitle:
            "180 days locking period gives you the following benefits for the staking.",
          desc: "Selecting the 180 days locking period you may use 100% of your APY boosts, and your APY could be between 30% and 40%",
        },
      ],
    };
  },
  methods: {
    selectedPeriod: function (item) {
      this.$gtm.trackEvent({
        event: "Period-Click",
        category: "Period",
        action: "Click" ,
        label: "SelectPeriod_"+ item.day,
        value: parseInt(item.day),
        noninteraction: false,
      });
      console.log("triggered-gtm");
      this.selectedLockPeriod = item.day;
      this.showLockinkModal = true;
    },
  },
};
</script>
<style lang=""></style>
