<template lang="">
  <svg
    width="60"
    height="61"
    viewBox="0 0 60 61"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M30 60.0156C46.5685 60.0156 60 46.5842 60 30.0156C60 13.4471 46.5685 0.015625 30 0.015625C13.4315 0.015625 0 13.4471 0 30.0156C0 46.5842 13.4315 60.0156 30 60.0156ZM18.4709 13.564C16.2868 13.564 14.5161 15.3346 14.5161 17.5188V42.7544C14.5161 44.9385 16.2868 46.7092 18.471 46.7092H39.5633C39.7655 46.7092 39.9641 46.694 40.1581 46.6647C42.6574 46.6798 45.1613 45.7331 47.0699 43.8246C50.857 40.0375 50.857 33.906 47.0699 30.1189C46.0303 29.0793 44.8141 28.3251 43.5181 27.8562V17.5188C43.5181 15.3346 41.7475 13.564 39.5633 13.564H18.4709ZM33.3641 43.8246C33.4487 43.9092 33.5345 43.9918 33.6213 44.0726H18.471C17.7429 44.0726 17.1527 43.4824 17.1527 42.7544V17.5188C17.1527 16.7908 17.7429 16.2006 18.4709 16.2006H39.5633C40.2914 16.2006 40.8816 16.7908 40.8816 17.5188V27.3012C38.1854 27.1168 35.427 28.056 33.3641 30.1189C29.577 33.906 29.577 40.0375 33.3641 43.8246ZM21.6725 21.2853C20.9445 21.2853 20.3543 21.8755 20.3543 22.6036C20.3543 23.3317 20.9445 23.9219 21.6725 23.9219H35.9852C36.7133 23.9219 37.3035 23.3317 37.3035 22.6036C37.3035 21.8755 36.7133 21.2853 35.9852 21.2853H21.6725ZM37.4862 39.7283C40.6034 42.8456 44.3648 44.211 45.9105 42.7683C45.9254 42.7535 45.9317 42.7472 45.9392 42.7445C45.9448 42.7426 45.9511 42.7426 45.9621 42.7426L46.0136 42.6653C49.1566 39.4965 49.1309 34.3697 45.9878 31.2267C44.4421 29.7067 42.4068 28.8565 40.2428 28.8565C38.0787 28.8565 36.0177 29.6809 34.4977 31.2267C33.0035 32.7209 34.3689 36.6111 37.4862 39.7283ZM20.3543 30.1367C20.3543 29.4086 20.9445 28.8184 21.6725 28.8184H28.8289C29.5569 28.8184 30.1471 29.4086 30.1471 30.1367C30.1471 30.8647 29.5569 31.4549 28.8289 31.4549H21.6725C20.9445 31.4549 20.3543 30.8647 20.3543 30.1367ZM21.6725 36.728C20.9445 36.728 20.3543 37.3182 20.3543 38.0462C20.3543 38.7743 20.9445 39.3645 21.6725 39.3645H26.569C27.297 39.3645 27.8872 38.7743 27.8872 38.0462C27.8872 37.3182 27.297 36.728 26.569 36.728H21.6725Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {
  name: "IconWhitelisting",
};
</script>
