<template>
  <svg
    class="mx-auto"
    width="44"
    height="43"
    viewBox="0 0 44 43"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M0.5 21.5C0.5 9.632 10.132 0 22 0C33.868 0 43.5 9.632 43.5 21.5C43.5 33.368 33.868 43 22 43C10.132 43 0.5 33.368 0.5 21.5ZM6.95 22.403L17.7 33.153L37.05 13.803L34.0185 10.75L17.7 27.0685L9.9815 19.3715L6.95 22.403Z"
      fill="#86CCB4"
    />
  </svg>
</template>

<script>
export default {
  name: "IconSuccess",
};
</script>
