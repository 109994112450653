import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import "./assets/styles/main.css";
import axios from "axios";
import VueAxios from "vue-axios";
import vTitle from "vuejs-title";
import directives from "./directives/";
import VueGtm from "vue-gtm";
import mitt from "mitt";

axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";
const emitter = mitt();
const app = createApp(App)
  .use(vTitle)
  .use(VueAxios, axios)
  .use(router)
  .use(VueGtm, {
    id: process.env.VUE_APP_GTM_ID,
    defer: false,
    enabled: true,
    debug: false,
    loadScript: true,
    vueRouter: router,
    trackOnNextTick: true,
  });

app.config.globalProperties.emitter = emitter;
directives(app);
app.mount("#app");
