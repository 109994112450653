import Home from "./../pages/Home.vue";
import Dashboard from "./../pages/Dashboard.vue";
import Token from "./../pages/Token.vue";
import { createRouter, createWebHistory } from "vue-router";
const routeInfos = [
  {
    name: "Dashboard",
    path: "/dashboard",
    props: false,
    component: Dashboard,
  },
  {
    name: "Home",
    path: "/",
    props: true,
    component: Home,
  },
  {
    name: "Token",
    path: "/token/:id",
    props: true,
    component: Token,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes: routeInfos,
});

export default router;
