<template>
  <div
    class="container mx-auto sm:px-3 mt-14 lg:mt-20 mb-14 lg:mb-20 px-3 relative"
  >
    <div class="flex flex-wrap">
      <div class="w-full">
        <div class="text-white text-center uppercase font-bold">
          <div class="text-center pb-3">
            <IconWhiteListing class="mx-auto" />
          </div>
          <h1 class="text-display-1">WHITELIST</h1>
          <h2 class="text-2xl my-4">Dear NEXTEARTHIAN</h2>
          <p class="text-lg max-w-4xl mx-auto">
            GET WHITELISTED FOR NXTT STAKING AND ACHIEVE THE MAXIMUM POSSIBLE
            APY BY SPENDING UNITS. WHITELISTED USERS WILL ALSO GET A GUARANTEED
            SPOT IN THE STARTING STAKING POOLS. THE WHITELISTING PROCESS IS ONE
            SIMPLE STEP: LOCK THE AMOUNT OF NXTT THAT YOU WISH TO STAKE LATER ON
          </p>
          <p class="text-lg max-w-4xl mx-auto mt-4">
            To begin with the whitelist process, please connect your wallet!
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import IconWhiteListing from "./icons/IconWhiteListing.vue";
export default {
  name: "InfoPanelSec",
  components: {
    IconWhiteListing,
  },
};
</script>
