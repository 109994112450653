<template>
  <svg
    class="mx-auto"
    width="44"
    height="44"
    viewBox="0 0 44 44"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M29.4736 17.16L26.6367 14.3231L21.9615 18.9983L17.2863 14.3231L14.4495 17.16L19.1246 21.8352L14.4495 26.5104L17.2864 29.3472L21.9615 24.6721L26.6367 29.3473L29.4736 26.5104L24.7984 21.8352L29.4736 17.16ZM0.461426 21.8352C0.461426 9.9672 10.0934 0.335205 21.9614 0.335205C33.8294 0.335205 43.4614 9.9672 43.4614 21.8352C43.4614 33.7032 33.8294 43.3352 21.9614 43.3352C10.0934 43.3352 0.461426 33.7032 0.461426 21.8352Z"
      fill="#CC8686"
    />
  </svg>
</template>

<script>
export default {
  name: "IconError",
};
</script>
