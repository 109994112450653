<template>
  <div class="relative container mx-auto sm:px-3 px-3 pb-5">
    <div class="px-4 pt-5">
      <h3 class="text-white text-3xl text-center font-bold uppercase -mb-5">
        {{ title }}
      </h3>
    </div>
    <div class="flex flex-col lg:flex-row pt-0 gap-10 lg:pt-12 pb-5">
      <InfoBox
        class="max-w-[480px] mx-auto"
        v-for="(step, idx) in items"
        :id="step.id"
        :key="`step${idx}`"
        :icon="step.icon"
        :title="step.title"
        :content="step.short_content"
      />
    </div>
  </div>
</template>

<script>
import InfoBox from "@/components/base/InfoBox.vue";
export default {
  name: "InfoPanelSec",
  components: {
    InfoBox,
  },
  props: ["title", "items"],
};
</script>
